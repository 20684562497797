@import 'scss/variables';
@import '~bootstrap/scss/bootstrap';

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

svg.aligned {
    vertical-align: text-bottom
}

#root {
    height: 100%;
}

.font-white {
    // TODO: This actually isn't WCAG-happy with our $celeste-pink background
    color: #FFF !important;
}

.btn-link:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}

.celeste-global-container {
    height: 100%;
    /* Breaking the responsive design of the bootstrap framework a bit here */
    padding: 0 !important;
    width: 100%;
    margin: 0;
    max-width: none !important;
}

h1.celeste {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #3A3456;
}

/* Everything below this is terrible and should be done with SASS mixins instead */
.bg-paper {
    background-color: #F6FFFD;
}

.border-celeste-primary {
    border: 1px solid #5552FF;
}

.font-celeste-purple {
    color: #52497D;
}

.font-celeste-pink {
    color: $celeste-pink;
}

.fakebutton:hover {
    cursor: pointer;
}

.fakebutton:active {
    box-shadow: 2px 2px 5px #c8c3df;
}

.btn-primary:not(.btn-link) {
    @include button-variant($celeste-pink, darken($celeste-pink, 7.5%), darken($celeste-pink, 10%), lighten($celeste-pink,5%), lighten($celeste-pink, 10%), darken($celeste-pink,30%));
}

.btn-outline-primary:not(.btn-link) {
    @include button-outline-variant($celeste-pink, #222222, lighten($celeste-pink,5%), $celeste-pink);
}

/* Provider dashboard */
ul.celeste.left-nav {
    padding-left: 0;
    padding-right: 0;
    list-style-type: none;
}

ul.celeste.left-nav li {
    margin-bottom: 48px;
}

ul.celeste.left-nav li:first-of-type {
    margin-top: 100px;
}

ul.celeste.left-nav li a {
    display: block;
    border-radius: 10px;
    text-decoration: none;
    color: #5552FF;
    font-size: 20px;
    padding: 10px 20px;
}

ul.celeste.left-nav li a.active {
    color: #FFFFFF;
    text-decoration: none;
    background: #5552FF;
}

ul.celeste.left-nav li a:not(.active):hover {
    background: #dde1f4;
}

.category {
    border-bottom: 1px solid $celeste-purple;
    padding-bottom: 12px;
    margin-bottom: 12px;

    .category-name {
        font-weight: $font-weight-semibold;
    }
}

.category.last {
    border-bottom: none;
}

/* Bootstrap Overrides */

/* Accordion stuff */
.celeste .accordion-button:not(.collapsed) {
    background-color: #A6AEEF;
    color: #000000;
}

.celeste .accordion-button {
    background-color: #52497D;
    color: #FFFFFF;
}

.celeste .accordion-body {
    background: #E0E0E2;
}

/* Provider UI */
.provider-content {
    color: $celeste-purple;

    h1 {
        color: $celeste-pink;
    }
}

.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px solid $celeste-purple;
}

table.rounded-corners>thead>tr {
    border-bottom: 1px solid $celeste-purple;
    font-weight: $font-weight-semibold;
}

/* Ensure that cells have no border at the bottom except for TH */
// table.rounded-corners>thead>tr,
// table.rounded-corners>thead>tr>th,
// table.rounded-corners>thead>tr>td,
table.rounded-corners>tbody>tr,
table.rounded-corners>tbody>tr>th,
table.rounded-corners>tbody>tr>td,
table.rounded-corners>tfoot>tr,
table.rounded-corners>tfoot>tr>th,
table.rounded-corners>tfoot>tr>td,
table.rounded-corners>tr,
table.rounded-corners>tr>td,
table.rounded-corners>tr>th,
table.rounded-corners>thead,
table.rounded-corners>tbody,
table.rounded-corners>tfoot {
    border-bottom: none;
}

table.rounded-corners tr td {
    padding: 20px;
}

.border-celeste-provider {
    border: 2px solid #52497D;
    border-radius: 10px;
    padding: 24px 20px;
    margin-top: 20px;
}

.labdetails {
    border-radius: 11px;
    padding: 12px 32px;
    margin-top: 4px;
}

.labdetails.normal {
    background: #52497D;
    color: #FFFFFF;
}

.labdetails.light {
    background: #A6AEEF;
    color: #000000;
}

.labdetails .row {
    margin-left: 28px;
    margin-right: 28px;
}

.intake-report .row {
    padding-left: 12px;
    padding-right: 12px;
}

.intake-report .content ul,
.intake-report .content ol {
    margin-left: 20px;
}

#patient_create_form .form-label {
    font-weight: bold;
}

#patient_create_form .follow-up-subsection {
    border: 1px inset #AAAAAA;
    padding: 8px;
}

#patient_create_form .follow-up-subsection>.heading {
    font-weight: bold;
    text-decoration: underline;
}

#patient_create_form .follow-up-subsection>.subheading-2 {
    font-weight: bold;
    text-decoration: underline;
}